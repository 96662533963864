import React from "react";
import "./Initiatives.css";
import Logo from "./Logo";
import IncubatorCard from "./IncubatorCard";

const initiatives = [
  {
    title: "Effective Altruism",
    description:
      "Supporting sustainable projects and innovative solutions, our Effective Altruism initiative maximizes positive impact for a greener, more equitable world.",
    status: "Live",
    img: "EA.webp",
    url: "https://forum.effectivealtruism.org/topics/opportunities-to-take-action",
  },
  {
    title: "EduDAO",
    description:
      "An incubator for the innovators of tomorrow that empower university groups and centers through resources and active capital, aiming to foster blockchain innovation for the benefit of all.",
    status: "Live",
    img: "logo-edudao.webp",
    url: "https://www.edudao.io/",
  },
  {
    title: "Game7",
    description:
      "A collective of gaming veterans and crypto-native leaders passionate about building a more equitable gaming industry — one where developers and players are on the same side.",
    status: "Live",
    img: "logo-game7.webp",
    url: "https://game7.io/",
  },
];

const Initiatives = () => {
  return (
    <div>
      <div className="initiatives-container" id="initiatives-container">
        <h1 style={{ fontWeight: 800, marginBottom: "100px" }}>
          We work with initiatives that drive ecosystem adoption
        </h1>
        <div className="initiatives-grid">
          {initiatives.map((initiative, index) => (
            <div key={index} className="initiative-card">
              <div className="initiative-card-header">
                <img
                  src={initiative.img}
                  alt={initiative.title}
                  className="initiative-img"
                  style={{ borderRadius: "10px" }}
                />
                <h2>{initiative.title}</h2>
              </div>
              <p>{initiative.description}</p>
              <a href={initiative.url} target="_blank" rel="noopener noreferrer">
                <button className="status-button">{initiative.status}</button>
              </a>
            </div>
          ))}
        </div>

        <section className="incubator-section ">
          <h1>Incubated by</h1>
          <div className="cards-container">
            <IncubatorCard
              image="/path/to/your/image1.png"
              title="Connect IRL"
              description=""
              linkText="View Events"
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Initiatives;
