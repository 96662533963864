import React from "react";
import "./Governance.css";

const Governance = () => {
  return (
    <div className="mantle-governance">
      <h1> Community-Driven Decisions for a Sustainable Future</h1>
      <div className="mantle-sections">
        <div className="mantle-section mantle-section1">
          <div className="mantle-content">
            <h2>Governance</h2>
            <p>
              All decisions, from launching new initiatives, to transferring and
              recalling treasury resources, assigning and modifying powers to
              operating teams, and implementing corrective actions, are subject
              to proposals and voting by $MNT token holders.
            </p>
            <div className="mantle-buttons">
            <button>Forum</button>
              
            </div>
          </div>
        </div>
        <div className="mantle-section mantle-section2">
          <div className="mantle-content">
            <h2>Resource Management</h2>
            <p>
              Through our transparent treasury management, community members can
              drive the allocation of funds towards incentive programs for
              designers, charity foundations, and other sustainable goals. Our
              collective decisions help us achieve effective altruistic
              outcomes, continuously improving our mission for a greener planet.
            </p>
            <div className="mantle-buttons">
              <div className="mantle-buttons">
                <button>Snapshot</button>
                <button>Treasury Monitor</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Governance;
