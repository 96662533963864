import React, { useState } from "react";
import "./Roadmap.css";

const FeatureCard = ({ title, description, comparison, isActive }) => (
  <div className={`feature-card ${isActive ? "active" : ""}`}>
    <h3>{title}</h3>
    <p>{description}</p>
    <div className="comparison">
      <span className="vs">vs</span>
      <p>{comparison}</p>
    </div>
  </div>
);

const Roadmap = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const features = [
    {
      title: "Community-Driven Design",
      description:
        "Our 3D designs are driven by open-source designers, incentivized through our DAO and rewards, ensuring constant innovation.",
      comparison:
        "Many eyewear brands limit design innovation to in-house teams, reducing diversity and creativity in their products.",
    },
    {
      title: "Sustainability",
      description:
        "Pendle Optical uses sustainable materials for all our 3D-printed eyewear, minimizing waste and reducing our carbon footprint.",
      comparison:
        "Traditional eyewear companies often rely on non-renewable resources and generate significant waste during production.",
    },

    {
      title: "Web3 Integration & Crypto Payments",
      description:
        "We are at the forefront of integrating Web3 technology, offering seamless crypto payment options for a modern shopping experience.",
      comparison:
        "Traditional retail stores often lack support for crypto payments, limiting payment options for tech-savvy consumers.",
    },
    {
      title: "DAO Governance",
      description:
        "Our business is driven by the community through a Decentralized Autonomous Organization (DAO), allowing customers to participate in decision-making and governance.",
      comparison:
        "Conventional companies usually operate with centralized decision-making, leaving little room for customer input or influence.",
    },
    {
      title: "Exclusive NFT Benefits",
      description:
        "Customers earn NFTs with every purchase, granting them exclusive rewards and voting power in our DAO, fostering a sense of ownership and engagement.",
      comparison:
        "Most eyewear brands do not offer digital rewards or ways for customers to have a stake in the company.",
    },
  ];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % features.length);
  };

  const prevSlide = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + features.length) % features.length
    );
  };

  return (
    <section>
      <div className="roadmap" id="roadmap">
        <div className="roadmap-content">
          <h2>Why Retail Chooses Pendle Optical</h2>
          <p className="roadmap-description">
            Our sustainable, open-source 3D designs are driven by designer
            incentives, a DAO, and Web3 integration, making us a
            community-driven business <br />
            Built by the community, for the community.
          </p>
          <div className="feature-cards-container">
            <div
              className="feature-cards"
              style={{
                transform: `translateX(-${activeIndex * (320 + 40)}px)`,
              }}
            >
              {features.map((feature, index) => (
                <FeatureCard
                  key={index}
                  {...feature}
                  isActive={index === activeIndex}
                />
              ))}
            </div>
            <div className="navigation-arrows">
              <button className="arrow left" onClick={prevSlide}>
                ←
              </button>
              <button className="arrow right" onClick={nextSlide}>
                →
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
