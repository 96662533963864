import React, { useState } from 'react';
import './IntroGrid.css';

const IntroGrid = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <div className={`intro-grid ${isDarkMode ? 'dark' : ''}`}>
      <div className="canvas">
        <div className="grain-overlay"></div>
        <div className="content-left">
          <h1>Welcome to the<br />Future of Eyewear.</h1>
          <button className="arrow-down">
            &#9660;
          </button>
        </div>
        <div className="content-right">
          <p>
          Designed with you in mind, our Web3 retail store combines cutting-edge 3D printing and sustainable materials. <br></br>Join our DAO, pay with crypto, and help shape the future of eyewear.
          </p>
          <div className="buttons">
            <button className="primary">Learn More</button>
            <button className="secondary">Explore Ecosystem</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroGrid;
