import React from "react";
import "./IncubatorCard.css";

function IncubatorCard({ image, title, description, linkText }) {
  return (
    <div className="incubator-card">
      <img
        loading="lazy"
        src="https://cdn.sanity.io/images/5mrqfjdi/production/d78cae20751688a4d073d28d82d30d2d5e2c8592-190x190.svg?w=200&amp;auto=format"
        srcSet="https://cdn.sanity.io/images/5mrqfjdi/production/d78cae20751688a4d073d28d82d30d2d5e2c8592-190x190.svg?w=200&amp;auto=format&amp;dpr=1 1x, https://cdn.sanity.io/images/5mrqfjdi/production/d78cae20751688a4d073d28d82d30d2d5e2c8592-190x190.svg?w=200&amp;auto=format&amp;dpr=2 2x, https://cdn.sanity.io/images/5mrqfjdi/production/d78cae20751688a4d073d28d82d30d2d5e2c8592-190x190.svg?w=200&amp;auto=format&amp;dpr=3 3x"
        alt="signal ventures logo"
        style={{
          width: "80%",
          height: "auto",
          minWidth: "120px",
          marginBottom: "20px",
          objectFit: "contain",
        }}
      />
      <div className="signal-ventures">
        <h2 className="signal-ventures-title">SIGNAL VENTURES</h2>
      </div>
    </div>
  );
}

export default IncubatorCard;
