// src/components/MainContent.js
import React from "react";
import "./MainContent.css";
import IncubatorCard from "./IncubatorCard"; // Import the IncubatorCard component
import Roadmap from "./Roadmap";
import Initiatives from "./Initiatives";
import Environment from "./Environment";
import Newsletter from "./Newsletter";
import Governance from "./Governance";
import IntroGrid from "./IntroGrid";
import Footer from "./Footer";

// import ParticleBackground   from "./ParticlesBackground";

function MainContent() {
  return (
    <main className="main-content">
      <div className="top" id="top">
        <section className="intro-section" >
          <IntroGrid/>
        </section>
      </div>

      <div>
        <Roadmap />
      </div>
      <section id="environment" className="environment">
        <div className="environment">
          <Environment />
        </div>
      </section>

      <section>
      <Governance/>

      </section>
      <section>
        <Initiatives />
      </section>
      <section>
        <div style={{ backgroundColor: "black", height:'800px'}}>
          <Newsletter />
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default MainContent;
