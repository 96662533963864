
import React from 'react';

const Logo = () => {
  return (
    <svg width="120" height="40" viewBox="0 0 200 100" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle' }}>
      <defs>
        <linearGradient id="textGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#72DAD8', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#B58DF6', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      
           {/* Spectacle frame */}
           <g transform="translate(70, 20) scale(1.2)" opacity="0.3">
        {/* Left lens */}
        <circle cx="40" cy="40" r="20" fill="white" stroke="#B58DF6" strokeWidth="3" />
        {/* Left eye */}
        <circle cx="40" cy="40" r="12" fill="#B58DF6" />
        <circle cx="40" cy="40" r="6" fill="white" />
        <circle cx="43" cy="37" r="3" fill="#B58DF6" />
        
        {/* Right lens */}
        <circle cx="90" cy="40" r="20" fill="white" stroke="#B58DF6" strokeWidth="3" />
        {/* Right eye */}
        <circle cx="90" cy="40" r="12" fill="#B58DF6" />
        <circle cx="90" cy="40" r="6" fill="white" />
        <circle cx="93" cy="37" r="3" fill="#B58DF6" />
        
        {/* Bridge */}
        <line x1="60" y1="40" x2="70" y2="40" stroke="#B58DF6" strokeWidth="3" />
        
        {/* Temple arms */}
        <line x1="20" y1="40" x2="10" y2="35" stroke="#B58DF6" strokeWidth="3" />
        <line x1="110" y1="40" x2="120" y2="35" stroke="#B58DF6" strokeWidth="3" />
      </g>
      
      
      {/* PENDLE text */}
      <text x="150" y="50" fontFamily="Circular, sans-serif" fontSize="48" fontWeight="bold" textAnchor="middle" dominantBaseline="middle" fill="url(#textGradient)">PENDLE</text>
    </svg>
  );
};

export default Logo;