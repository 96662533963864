import React from 'react';
import './Newsletter.css';


const Newsletter = () => {
  return (
    <div className="newsletter-container">
      <div className="overlay-noise"></div>
      <div className="overlay-gradient"></div>
      <div className="content">
        {/* <div className="indicator">
          <span className="indicator-dot"></span>
          <h6>Stay up to date</h6>
        </div> */}
        <p>Be the first to know about the latest updates and launches.</p>
        <div className="form-container">
          <form className="newsletter-form">
            <input type="email" placeholder="Email" required name="email" />
            <button type="submit" aria-label="Newsletter Submit Subscription Button">
              <span>+</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
